jQuery( document ).ready(function($) {

  AOS.init({
    once: true,
    duration: 1000,
  });

  swiper = new Swiper('.slider-music', {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
    }
  });

  // Campaign JS Code
  $("#zach-john-king-email-sign-up").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('.form-alert.success').show();
        $('.form-alert.error, #zach-john-king-email-sign-up').hide();
      },
      error: function (err) {
        // display block css
        $('.form-alert.error').show();
      }
    });
  });

  $('body').on('change', '.mailing-list-id', function () {
  $( "#ts-for-"+$(this).attr('id')).prop( "checked", $(this).is(':checked') );
  });

  setTimeout(function(){$('.bit-play-my-city-wrapper+div a').attr('aria-label', "BandsInTown")  }, 500);

});